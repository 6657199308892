import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/global.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/libs/shared/utilities/src/lib/get-mobile-breakpoint.ts");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"fonts.ts\",\"import\":\"Space_Grotesk\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-space-grotesk\"}],\"variableName\":\"spaceGrotesk\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"fonts.ts\",\"import\":\"DM_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"weight\":\"500\",\"variable\":\"--font-dm-sans\"}],\"variableName\":\"dmSans\"}");
